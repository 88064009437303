import React from "react";
import classes from "./list-item.module.css";
import Snackbar from "@material-ui/core/Snackbar";
import { initializeStore } from "/components/store/store";
import Link from "next/link";
import { useState, useEffect } from "react";
import { parseCookies } from "nookies";
import WishlistButton from "../WishlistButton/WishlistButton";
import { Alert } from "@material-ui/lab";
import { getWebsiteID } from "/lib/common-functions";
import { useCookies } from "react-cookie";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import FormLoader from "../../components/ui/FormLoader";
import Image from "/components/CustomImage";
import { getReduxSession } from "../../lib/sessionHandler";
import { EventCommonObj } from "../../lib/services/helpers/eventCommonObject";
import { setVideoStatus } from "../../lib/services/helpers/setVideoStatus";
import cancel_icon from '~/public/images/cancel-icon.inline.svg';
import video_icon from '~/public/images/video-icon.inline.svg';
import star from '~/public/images/star.inline.svg';
import desk_bag_white from '~/public/images/desk-bag-white.inline.svg';
import { useIsMobile } from "/lib/hooks/useIsMobile";
import { truncateTitle } from "/lib/services/helpers/truncateTitle";
import { getWebsiteURL } from "/lib/services/helpers/getWebsiteURL";


function YouTubeGetID(url) {
	var ID = "";
	url = url
		.replace(/(>|<)/gi, "")
		.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
	if (url[2] !== undefined) {
		ID = url[2].split(/[^0-9a-z_\-]/i);
		ID = ID[0];
	} else {
		ID = url;
	}
	return ID;
}

const VideoIconClickDynamic = async (name: any, url_key: any, eventCommonObj: any) => {
	const gaModule = await import('../../lib/ga');
	const ga = gaModule as typeof import('../../lib/ga');
	ga.VideoIconClick(name, url_key, ' ', eventCommonObj);
};

const AddToBagEventDynamic = async (props, cPrice, mPrice, session, variantLabel, sku, qty, website_id, eventCommonObj) => {
	const gaModule = await import('../../lib/ga');
	const ga = gaModule as typeof import('../../lib/ga');
	ga.AddToBagEvent(props, cPrice, mPrice, session, variantLabel, sku, qty, website_id, eventCommonObj, '');
};

const ProductClicksDynamic = async (props, session, website_id, variantLabel, eventCommonObj) => {
	const gaModule = await import('../../lib/ga');
	const ga = gaModule as typeof import('../../lib/ga');
	ga.ProductClicks(props, session, website_id, variantLabel, eventCommonObj, '')
};

function ListItemHome(props) {
	const session = getReduxSession();
	const [showLoader, setShowLoader] = useState(false);
	const isMobileDevice = useIsMobile();
	const cookies = parseCookies(null, "fromClient");
	const [cookie, setCookie] = useCookies(["store"]);
	const [utmsource, setUtmsource] = useCookies(["utm_source"]);
	let website_id = getWebsiteID(cookie);
	const reduxStore = initializeStore();
	const { dispatch } = reduxStore;
	const {
		storeid,
		name,
		sku,
		image,
		description,
		slug,
		id,
		url_path,
		url_key,
		price,
		mprice,
		type,
		conf,
		variants,
		stock,
		salableqty,
		media_gallery,
		rating_summary,
		review_count,
		product_label,
		pwa_oneliner,
		is_home,
		lifestyle_flag,
		showDec = true
	} = props;

	const useStyles = useIsMobile()
		? makeStyles((theme) => ({
			paper: {
				position: "absolute",
				width: "90%",
				background: "#fff",
			},
		}))
		: makeStyles((theme) => ({
			paper: {
				position: "absolute",
				width: "60%",
				background: "#fff",
			},
		}));

	const [modalStyle] = React.useState(getModalStyle);
	const coreclasses = useStyles();
	function getModalStyle() {
		const top = 50;
		const left = 50;

		return {
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`,
		};
	}
	const categoryUrlSuffix = ".html";
	let firstvideo = media_gallery
		? media_gallery.filter((item) => item.__typename === "ProductVideo")
		: [];

	firstvideo = firstvideo.length > 0 ? firstvideo[0] : null;
	const ConditionalWrapper = ({ condition, children }) =>
		condition ? children : "";

	const strippedTitle =
		name.length > 80
			? `${name.substr(0, 80).toLowerCase()}..`
			: name.toLowerCase();

	const strippedContent = pwa_oneliner
		? pwa_oneliner : (description ? (description.length > 90
			? `${description.substr(0, 90).toLowerCase().replace(/<[^>]+>/g, "")}..`
			: description.toLowerCase().replace(/<[^>]+>/g, "")) : '');

	const [showMe, setShowMe] = useState(false);
	const [modalopen, setModalOpen] = useState(false);
	const [ButtonText, setButtonText] = useState("Add to Bag");
	const [mediaImage, setMediaImage] = useState(image);
	const [bSnackbarVisible, setTriggerShowSnackbar] = useState(false);

	const [variantLabel, setVariantLabel] = useState(null);
	const [cPrice, setCPrice] = useState(price); // final price
	const [mPrice, setMPrice] = useState(mprice); // regular price
	const [vPrice, setVPrice] = useState(instockVariantPrice(variants));

	const eventCommonObj = EventCommonObj(session, cookie)

	function instockVariantPrice(variants) {
		let variantPrice = 0;
		if (variants && variants.length) {
			for (let i = 0; i < variants.length; i++) {
				if ((variants[i].product.stock_status == 'IN_STOCK')) {
					variantPrice = variants[i].product.price_range.minimum_price.regular_price.value;
					break;
				}
			}
		}
		return variantPrice;
	}

	const [selectedIndex, setSelectedIndex] = useState(-1);
	const currency = storeid == 2 ? "$" : "₹";
	let prefixUrl = getWebsiteURL(storeid)
	const setPrice = async (event) => {
		const selectedsku = event.target.value;
		for (const [index, variant] of variants.entries()) {
			if (variant.product.sku == selectedsku) {
				setCPrice(variant.product.price.regularPrice.amount.value);
				setMPrice(variant.product.price_range.minimum_price.regular_price.value)
				setVPrice(variant.product.price_range.minimum_price.regular_price.value);
				setVariantLabel(variant?.attributes[0]?.label);
				setSelectedIndex(index);
				setMediaImage(variant.product.small_image.url)
			}
		}
	};
	useEffect(() => {
		setMediaImage(image)
	}, [image]);
	useEffect(() => {
		setCPrice(price)
		setMPrice(mprice)
		setVPrice(instockVariantPrice(variants));
	}, [id]);
	function isDefaultSelected(index) {
		if (selectedIndex == -1) {
			const value =
				variants[0].product.stock_status == "OUT_OF_STOCK" || (variants[0].product.salableqty.label &&
					variants[0].product.salableqty.label <= 0)
					? variants[index].product.stock_status == "IN_STOCK"
						? true
						: false
					: index == 0
						? true
						: false;
			return value;
		} else if (index == selectedIndex) {
			return true;
		} else return false;
	}

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		//setOpen(false);
		setTriggerShowSnackbar(false);
	};

	function toggle() {
		setShowMe(!showMe);
	}
	const handleOpen = (e) => {
		e.preventDefault();
		VideoIconClickDynamic(name, url_key, eventCommonObj);

		setVideoStatus(id);

		setModalOpen(true);
	};

	const handleMClose = (e) => {
		e.preventDefault();
		setModalOpen(false);
	};

	const videoIframe = firstvideo ? (
		<>

			<a className={classes.cancelbutton} onClick={handleMClose}>
				<img width="20px" height='20px' src={cancel_icon} alt="cancel" loading="lazy" />
			</a >
			<iframe
				width="100%"
				height="375"
				src={`https://www.youtube.com/embed/${YouTubeGetID(
					firstvideo.video_content.video_url
				)}?rel=0`}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
				loading="lazy"
			/>
		</>
	) : (
		""
	);
	const addtobag = async (event) => {
		event.preventDefault();
		setShowLoader(true)
		setButtonText("Please Wait");
		try {
			const res = await fetch("/api/addtobag", {
				body: JSON.stringify({
					parentsku:
						event.target.parentsku && event.target.parentsku.value
							? event.target.parentsku.value
							: "",
					sku: event.target.sku.value,
					qty: event.target.qty.value,
					cookie: cookies,
				}),
				headers: {
					"Content-Type": "application/json",
				},
				method: "POST",
			});

			AddToBagEventDynamic(props, cPrice, mPrice, session, variantLabel, event.target.sku.value, event.target.qty.value, website_id, eventCommonObj)

			const bagdata = await res.json();
			if (bagdata?.message) {
				dispatch(
					{
						type: 'RESPONSE_MSG',
						message: bagdata.message,
						message_type: 'error',
					})
				setShowLoader(false)
				setButtonText("Add to Bag");
			} else {
				setShowLoader(false)
				setButtonText("Added");
				let cartLink = (website_id == 1) ? "/checkout/cart" : "/international/checkout/cart"
				let cartMsg = `Product added to Bag! <a href="${cartLink}">VIEW BAG</a>`
				dispatch(
					{
						type: 'RESPONSE_MSG',
						message: cartMsg,
						message_type: 'success',
					})
				let Qty = (bagdata.total) ? (bagdata.total) : 1
				dispatch(
					{
						type: 'INCREMENT',
						count: Qty,
					})
				dispatch(
					{
						type: 'MINICARTTOTAL',
						total_price: bagdata.grand_total,
					})
				setButtonText("Add to Bag");

				const customEvent = new CustomEvent('update', {});
				document.dispatchEvent(customEvent);
				//for times pixel
				if (utmsource['utm_source'] == 'Colombia') {
					function setAttributes(element, attributes) {
						Object.keys(attributes).forEach(attr => {
							element.setAttribute(attr, attributes[attr]);
						});
					}
					const attributes = {
						id: 'timesimg',
						height: '1',
						width: '1',
						style: 'display:none',
					};
					var img = document.createElement("img");
					setAttributes(img, attributes);
					img.src = "https://ade.clmbtech.com/cde/eventTracking.htm?pixelId=14381&_w=1&_t=1";
					var element = document.querySelector('head');
					element.appendChild(img);
					img.remove();
				}
			}
		} catch (error) {
			console.log('error addtobag', error);
			setShowLoader(false)
			setButtonText("Add to Bag");
		}
	};
	const body = (
		<div style={modalStyle} className={`${coreclasses.paper}`}>
			{videoIframe}
		</div>
	);
	return (
		<div id={`product_${id}`}>
			<FormLoader show={showLoader} />
			<div className={`${props.classCrossSell ? 'crossSellCarouselItem' : ''} ${lifestyle_flag ? `${classes.productitem} ${classes.lifestyle_img}` : classes.productitem}`}>
				<div className="item_home">
					{showDec ? <div className={`video_icon ${classes["video_icon"]}`}>
						{firstvideo && (
							<>
								<a onClick={handleOpen}>
									<img loading='lazy' src={video_icon} alt="video icon" width="30px" height={18} />
								</a >
								<Modal
									open={modalopen}
									onClose={handleMClose}
									aria-labelledby="product-video"
									aria-describedby="product-video"
								>
									{body}
								</Modal>
							</>
						)
						}
					</div > : null}
					{
						(!is_home && showDec) ? (<WishlistButton
							product={props}
							parentSku={sku}
							name={name}
							sku={variants && variants[0] ? variants[0].product.sku : sku}
							variantLabel={variantLabel}
							eventCommonObj={eventCommonObj}
							productprice={mPrice}
							productSellPrice={cPrice}
						/>) : null
					}
					{
						(!is_home) ? (<Link
							prefetch={false}
							href={{
								pathname: "/_url-resolver",
								query: {
									pathname: `${url_path + categoryUrlSuffix}`,
									type: "PRODUCT",
									url_path: url_path,
									url_key: url_key,
									cat_id: id,
								},
							}}
							as={`${prefixUrl}/${url_path + categoryUrlSuffix}`}
							onClick={() => ProductClicksDynamic(props, session, website_id, variantLabel, eventCommonObj)}>

							<span>
								{
									(mediaImage) ? (
										<Image loading="lazy" src={mediaImage} alt={name} title={name} placeholder="blur" blurDataURL="/images/kama-placeholder-kama-w.jpg" width={450} height={450} />
									) : <Image loading="lazy" src={'/images/kama-placeholder-kama.jpg'} alt={name} title={name} placeholder="blur" blurDataURL={'/images/kama-placeholder-kama.jpg'} width={450} height={450} />
								}
							</span >

						</Link >) : (
							<span>
								{
									(mediaImage) ? (
										<Image loading="lazy" src={mediaImage} alt={name} title={name} placeholder="blur" blurDataURL="/images/kama-placeholder-kama-w.jpg" width={450} height={450} />
									) : <Image loading='lazy' src={'/images/kama-placeholder-kama.jpg'} alt={name} title={name} placeholder="blur" blurDataURL={'/images/kama-placeholder-kama.jpg'} width={450} height={450} />
								}
							</span>
						)
					}
					<div className="item_home_main">
						<div className={classes.category_star}>
							{(rating_summary / 20).toFixed(1) > 2 ? (
								<>
									{product_label ? <h6>{isMobileDevice ? truncateTitle(product_label, 12) : truncateTitle(product_label, 26)}</h6> : null}
									<div className={classes.category_row}>
										{" "}
										{(rating_summary / 20).toFixed(1)}{" "}
										<img loading='lazy' src={star} width={11} height={10} alt="star icon" />{" "}
										({review_count})
									</div>
								</>
							) : null}
						</div>

						{
							(!is_home) ? (<Link
								prefetch={false}
								href={{
									pathname: "/_url-resolver",
									query: {
										pathname: `${url_path + categoryUrlSuffix}`,
										type: "PRODUCT",
										url_path: url_path,
										url_key: url_key,
										cat_id: id,
									},
								}}
								as={`${prefixUrl}/${url_path + categoryUrlSuffix}`}
								onClick={() => ProductClicksDynamic(props, session, website_id, variantLabel, eventCommonObj)}>

								<h3>{strippedTitle}</h3>

							</Link>) : (
								<h3>{strippedTitle}</h3>
							)
						}

						{!isMobileDevice && showDec ? <p>{strippedContent}</p> : ''}

						{stock == "IN_STOCK" && cPrice != 0 ? (
							<h4>
								{currency}
								{cPrice.toFixed(2)}
								{vPrice && (vPrice > 0) && (vPrice > cPrice) ?
									<abbr className={classes.cutoffprice}>{currency}{vPrice.toFixed(2)}</abbr>
									: mPrice > cPrice && <abbr className={classes.cutoffprice}>{currency}
										{mPrice.toFixed(2)}</abbr>}
							</h4>
						) : variants && variants[0] ? (
							<h4>
								{currency}
								{variants[0].product.price.regularPrice.amount.value.toFixed(2)}
								{vPrice && (vPrice > 0) && (vPrice > variants[0].product.price.regularPrice.amount.value) ?
									<span className={classes.cutoffprice}>{currency}{vPrice.toFixed(2)}</span>
									: ''}
							</h4>
						) : (
							<h4>
								{currency}
								{cPrice.toFixed(2)}
							</h4>
						)}
					</div>
				</div >

				{(stock == "OUT_OF_STOCK" || (salableqty && salableqty <= 0)) && (
					<button className={classes.outofstock}>Out Of Stock</button>
				)}
				{
					(stock == "IN_STOCK" && (!salableqty || salableqty > 0)) && (
						<div className={classes.btnbag}>
							<ConditionalWrapper
								condition={type == "simple" || conf?.[0]?.values.length == 1}
							>
								<form onSubmit={addtobag}>
									<ConditionalWrapper condition={type == "configurable"}>
										<input type="hidden" name="parentsku" value={sku} />
										<input
											type="hidden"
											name="sku"
											value={variants && variants[0] ? variants[0].product.sku : sku}
										/>
									</ConditionalWrapper>
									<ConditionalWrapper condition={type == "simple"}>
										<input type="hidden" name="sku" value={sku} />
									</ConditionalWrapper>

									<input type="hidden" name="qty" value="1" />

									<button type="submit"><img loading='lazy' src={desk_bag_white} alt="bag icon" height="16" width="16" /> {ButtonText}</button>
								</form>
							</ConditionalWrapper>
							<ConditionalWrapper
								condition={type == "configurable" && conf?.[0]?.values.length > 1}
							>
								<form onSubmit={addtobag}>
									<ConditionalWrapper condition={type == "configurable"}>
										<input type="hidden" name="parentsku" value={sku} />
									</ConditionalWrapper>

									<input type="hidden" name="qty" value="1" />

									<button
										type="submit"
										style={{
											display: showMe ? "block" : "none",
										}}
									><img loading='lazy' src={desk_bag_white} alt="bag icon" height="16" width="16" /> {ButtonText}
									</button>

									<button
										onClick={toggle}
										style={{
											display: showMe ? "none" : "block",
										}}
									> <img loading='lazy' src={desk_bag_white} alt="bag icon" width="16" height="19" /> Add to Bag
									</button>
									<div
										style={{
											display: showMe ? "block" : "none",
										}}
										className={classes.view_size_colm}
									>
										<div className={classes.col}>
											<h6>Choose size</h6>
											<a className={classes.close} onClick={toggle}>
												<img width={11} height={11} loading='lazy' src={cancel_icon} alt="close" />
											</a >
										</div >
										<table>
											<tbody>
												{conf?.length > 0 &&
													variants.map((value, index) => (
														<tr key={index}>
															<td>
																<label>
																	<input
																		type="radio"
																		name="sku"
																		placeholder=""
																		value={variants[index].product.sku}
																		defaultChecked={isDefaultSelected(index)}
																		disabled={
																			variants[index].product.stock_status ==
																				"OUT_OF_STOCK" || (variants[index].product.salableqty.label &&
																					variants[index].product.salableqty.label <= 0)
																				? true
																				: false
																		}
																		onClick={setPrice}
																	/>
																	{variants[index].attributes[0].label}
																	<i>{variants[index].product.stock_status ==
																		"OUT_OF_STOCK" || (variants[index].product.salableqty.label &&
																			variants[index].product.salableqty.label <= 0)
																		? ' Out of Stock'
																		: ''}</i>
																</label>
															</td>
															<td>
																{currency}
																{
																	variants[index].product.price.regularPrice
																		.amount.value.toFixed(2)
																}
																{variants[index].product.price_range.minimum_price.regular_price
																	.value > variants[index].product.price.regularPrice
																		.amount.value && <abbr className={classes.cutoffprice}>{currency}
																		{variants[index].product.price_range.minimum_price.regular_price
																			.value.toFixed(2)}</abbr>}
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div >
								</form >
							</ConditionalWrapper >
						</div >
					)
				}
			</div >
			{bSnackbarVisible && (
				<Snackbar
					TransitionComponent="down"
					open={open}
					autoHideDuration={1500}
					onClose={handleClose}
				>
					<Alert onClose={handleClose} severity="success">
						Product added to Bag!
					</Alert>
				</Snackbar>
			)}
		</div >
	);
}

export default ListItemHome;